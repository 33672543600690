import React, { useEffect, useState } from "react";

interface MenuItem {
  title: string;
  path: string;
}

type FooterMenuProps = {
  menu: MenuItem[];
  menuTitle: string;
  isLastItem: boolean;
};

export const FooterMenu = (props: FooterMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  //   Updating menu open state based on the screen size
  useEffect(() => {
    const updateWindowSize = () => {
      setIsMobileView(window.innerWidth < 770);
      if (window.innerWidth < 770) {
        setIsOpen(true);
      } else {
        setIsOpen(true);
      }
    };
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);
    return () => {
      window.removeEventListener("resize", updateWindowSize);
    };
  }, []);
  return (
    <div>
      <div className="flex justify-start items-center">
        <h3
          tabIndex={0}
          aria-label={props.menuTitle}
          className="font-mstroke-linecap bg-text-gradient text-transparent bg-clip-text font-semibold cursor-pointer"
          onClick={() => isMobileView && setIsOpen(!isOpen)}>
          {props.menuTitle}
        </h3>
        <span>
          {/* {
            isMobileView&&
          <svg
            onClick={() => isMobileView&&setIsOpen(!isOpen)}
            className={`w-2.5 h-2.5 ms-3 ${isOpen && "rotate-180"
              } cursor-pointer`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6">
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 4 4 4-4"></path>
          </svg>
          } */}
        </span>
      </div>
      {isOpen && (
        <div className="flex flex-col mt-3">
          {props.menu?.map((item, index) => (
            <a
              key={item.title}
              href={item.path || "#"}
              className={`page-loader py-1 text-sm text-tollguru-text hover:text-tollguru-primary ${
                props.isLastItem && index === props.menu.length - 1
                  ? "mb-8"
                  : ""
              }`}>
              {item.title}
            </a>
          ))}
        </div>
      )}
    </div>
  );
};
